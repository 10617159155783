import { NotificationBing, TruckFast } from 'iconsax-react'
import React, { useState } from 'react'
import { FaArrowAltCircleRight } from 'react-icons/fa'
import RadioForm from '../../../components/RadioForm'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setMethodCheckout } from '../../../reduxs/reducers/order-letter.reducer'
import { formatMoney } from '../../../utils/renderText'
import ButtonDeactive from '../../../components/ButtonDeactive'
import { setIsShowNotifyNotLogin } from '../../../reduxs/reducers/auth.reducer'

export default function ProductInforHandmade({ detail }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isAuthentication } = useSelector(state => state.auth)

    const { name, feeShip, id, sold_out } = detail.productDetail
    const { purchaseType } = detail

    const [isMethodSelected, setIsMethodSelected] = useState(purchaseType[0].purchase_type_id)
    const [priceMethod, setPriceMethod] = useState(purchaseType[0].price)

    const handleSelectMethod = (e) => {
        const methodId = e.target.value
        setIsMethodSelected(Number(methodId))
        const getPriceMethod = purchaseType.find(item => item.purchase_type_id === Number(methodId))
        setPriceMethod(getPriceMethod.price)
    }

    const handleSubmit = () => {
        if (isAuthentication) {
            dispatch(setMethodCheckout({ method: isMethodSelected }))
            navigate(`/checkout/handmake/${id}`)
        } else {
            dispatch(setIsShowNotifyNotLogin())
        }
    }

    return (
        <section className='flex flex-col lg:gap-5 gap-4 items-center'>
            <h4 className='lg:text-24-30 font-semibold w-full text-start'>{name}</h4>

            <div className='flex flex-col items-center gap-5 w-full'>
                <div className='flex justify-between items-center w-full'>
                    <div className='flex gap-5 font-bold items-center'>
                        <p>판매가격</p>
                        <p className='text-logoColor'>{formatMoney(priceMethod)} 원</p>
                    </div>

                    {
                        isMethodSelected !== 1
                        &&
                        <div className='flex gap-5 font-bold items-center'>
                            <div className='flex items-center gap-2'>
                                <TruckFast size={20} variant='Bold' />
                                <span>배송비</span>
                            </div>

                            <p className='text-logoColor'>{formatMoney(feeShip)} 원</p>
                        </div>}
                </div>

                <div className='flex gap-2 items-center text-blue font-bold w-full'>
                    <NotificationBing size={24} />
                    <span className='text-14_20'>이 공구 상품은 제작 상품으로 판매자에 의해 취소/반품/교환이 제한 될 수 있습니다.</span>
                </div>

                <p className='flex items-center gap-2 w-full'>자료구매옵션 <FaArrowAltCircleRight /></p>

                <div className='flex gap-5 w-full'>
                    {
                        purchaseType.map(item =>
                            <RadioForm
                                key={item.id}
                                name='method'
                                content={item.name}
                                onChange={handleSelectMethod}
                                value={item.purchase_type_id}
                                isCheck={isMethodSelected === item.purchase_type_id}
                            />)
                    }
                </div>

                <div className='flex items-center justify-between border-t border-strock pt-5 w-full'>
                    <div className='flex items-center gap-2.5'>
                        <span>총 상품 수량</span>
                        <span className='text-logoColor font-semibold'>1</span>
                    </div>

                    <div className='flex items-center gap-2.5'>
                        <p>총 수량 1개</p>
                        <span className='text-logoColor font-semibold'>{formatMoney(priceMethod + (isMethodSelected !== 1 && feeShip))}원</span>
                    </div>
                </div>

                {
                    sold_out
                        ?
                        <ButtonDeactive text='구매하기' />
                        :
                        <ButtonPrimary text='구매하기' className='w-[160px]' onClick={handleSubmit} />
                }
            </div>
        </section>
    )
}
