import React, { useState } from 'react'
import Pagination from '../../../components/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import { changeStatusOrder, downloadPostOfficeOrder, getPostOfficeOrder } from '../../../reduxs/actions/admin.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import moment from 'moment/moment'
import { StatusOrderSelect } from '../../../components/user/StatusOrder'
import DetailOrderPopup from './DetailOrderPopup'
import ButtonStatus from '../../../components/admin/ButtonStatus'
import { URL_FILE, URL_SERVER } from '../../../utils/apis'
import axios from 'axios'
import { formatMoney } from '../../../utils/renderText'
import EmptySection from '../EmptySection'
import { useLayoutEffect } from 'react'
import DropdownTable from '../../../components/admin/DropdownTable'
import { deleteOrderAdmin } from '../../../reduxs/actions/order.action'
import ButtonSecon from '../../../components/ButtonSecon'
import ButtonPrimary from '../../../components/ButtonPrimary'
// import DownloadQuillLetter from '../../../components/admin/DownloadQuillLetter'

export default function ListOrder() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { dataPostOffice, loadingGetPostOffice, postOfficeCount, dataStatusOrder } = useSelector(state => state.admin)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(postOfficeCount / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useLayoutEffect(() => {
        dispatch(getPostOfficeOrder(page))
    }, [page])

    if (loadingGetPostOffice || !dataPostOffice) {
        return <LoadingUser />
    }

    if (dataPostOffice.length <= 0) {
        return <EmptySection />
    }

    return (
        <section className='bg-white rounded-24 px-5'>
            <table className='w-full text-center'>
                <thead>
                    <tr className='border-b border-strock'>
                        <th className='py-5 px-2 w-[300px]'>접수일</th>
                        <th className='py-5 px-2 w-[300px]'>보내는분</th>
                        <th className='py-5 px-2 w-[300px]'>받는분</th>
                        <th className='py-5 px-2 w-[300px]'>결제방법</th>
                        <th className='py-5 px-2 w-[200px]'>상태</th>
                        <th className='py-5 px-2 w-[300px]'>결제금액</th>
                        <th className='py-5 px-2 w-[160px]'>편지 인쇄</th>
                        <th className='py-5 px-2 w-[160px]'>PDF 인쇄</th>
                        <th className='py-5 px-2 w-[160px]'>자료 인쇄</th>
                        <th className='py-5 px-2 w-5'></th>
                    </tr>
                </thead>

                <tbody>
                    {
                        dataPostOffice.length > 0
                            ?
                            dataPostOffice.map(item =>
                                <RowOrderItem
                                    key={item.order_id}
                                    id={item.order_id}
                                    sender={item.sender_name}
                                    receiver={item.recipient_name}
                                    status={item.order_status_id}
                                    statusName={item.status_name}
                                    created_at={item.created_at}
                                    money={item.money_pay}
                                    methodPay={item.pay_method_name}
                                    pdf={item.pdf}
                                    materials={item.materials}
                                    listStatus={dataStatusOrder}
                                    page={page}
                                />
                            )
                            :
                            ''
                    }
                </tbody>
            </table>

            <Pagination totalCount={postOfficeCount} currentPage={page} handlePage={handlePage} />
        </section>
    )
}

const RowOrderItem = ({ id, sender, receiver, status, created_at, money, methodPay, pdf = [], materials = [], listStatus, page }) => {
    const dispatch = useDispatch()

    const [isNotify, setIsNotify] = useState(false)
    const [isShow, setIsShow] = useState(false)
    const [isShowDelete, setIsShowDelete] = useState(false)
    const [isLoadingLetter, setIsLoadingLetter] = useState(false)
    const [isLoadingPdf, setIsLoadingPdf] = useState(false)
    const [isLoadingMaterial, setIsLoadingMaterial] = useState(false)

    // download pdfs
    const handleDownloadPdf = async () => {
        try {
            setIsLoadingPdf(true)

            const downloadPromises = pdf.map(file =>
                axios.get(`${URL_FILE}/${file.filename}`, { responseType: 'blob' })
            );

            const responses = await Promise.all(downloadPromises);

            responses.forEach((response, index) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${pdf[index].pdf_name}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoadingPdf(false)
        }
    }

    // download material
    const handleDownloadMaterial = async () => {
        try {
            setIsLoadingMaterial(true)

            const downloadPromises = materials.map(file =>
                axios.get(`${URL_FILE}/${file.filename}`, { responseType: 'blob' })
            );

            const responses = await Promise.all(downloadPromises);

            responses.forEach((response, index) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = `${materials[index].pdf_name}`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                window.URL.revokeObjectURL(url);
            });
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoadingMaterial(false)
        }
    }

    // download letter
    const handleDownloadLetter = async () => {
        setIsLoadingLetter(true)

        const result = await dispatch(downloadPostOfficeOrder(id))

        const res = result.payload

        if (res.status) {
            try {
                const { filelink, filename } = res.data
                const response = await axios.get(`${URL_SERVER}/${filelink}`, { responseType: 'blob' })
                const url = window.URL.createObjectURL(response.data)

                const anchor = document.createElement('a');
                anchor.href = url;
                anchor.download = filename;
                document.body.appendChild(anchor);
                anchor.click();

                // Cleanup
                anchor.remove();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                setIsNotify(error)

            } finally {
                setTimeout(() => {
                    setIsNotify()
                }, 3000);
            }
        } else {
            setIsNotify(res.message)

            setTimeout(() => {
                setIsNotify()
            }, 3000);
        }

        setIsLoadingLetter(false)
    }

    const handleShow = () => {
        setIsShow(!isShow)
    }

    const handleChangeStatus = (statusId) => {
        if (Number(status) !== Number(statusId)) return dispatch(changeStatusOrder({ order_id: id, status_id: statusId }))
    }

    const handleShowpopup = () => {
        setIsShowDelete(!isShowDelete)
    }

    const handleDeleteOrder = async () => {
        const result = await dispatch(deleteOrderAdmin({ orderId: id, page }))

        const res = result.payload

        if (res.status) {
            setIsNotify('콘텐츠가 삭제되었습니다.')
            handleShowpopup()
        } else {
            setIsNotify(res.message)
        }

        setTimeout(() => {
            setIsNotify()
        }, 3000);
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5 px-2 cursor-pointer' onClick={handleShow}>{moment(created_at).format('YYYY.MM.DD hh:mm')}</td>
            <td className='py-5 px-2 cursor-pointer' onClick={handleShow}>{sender}</td>
            <td className='py-5 px-2 cursor-pointer' onClick={handleShow}>{receiver}</td>
            <td className='py-5 px-2 cursor-pointer' onClick={handleShow}>{methodPay}</td>
            <td className='py-5 px-2'>
                <div className='flex justify-center'>
                    <StatusOrderSelect listStatus={listStatus} status={status} handleChange={handleChangeStatus} />
                </div>

                {
                    isShow
                    &&
                    <DetailOrderPopup orderId={id} handleShow={handleShow} />
                }
            </td>
            <td className='py-5 px-2'>₩{formatMoney(money)}원</td>
            <td className='py-5 px-2'>
                {/* <DownloadQuillLetter fileName={letter_name} data={letter} /> */}
                <div className='flex justify-center'>
                    <ButtonStatus status={true} isLoading={isLoadingLetter} onClick={handleDownloadLetter} />
                </div>
            </td>
            <td className='py-5 px-2'>
                <div className='flex justify-center'>
                    <ButtonStatus status={!!(pdf.length)} isLoading={isLoadingPdf} onClick={handleDownloadPdf} />
                </div>
            </td>
            <td className='py-5 px-2'>
                <div className='flex justify-center'>
                    <ButtonStatus status={!!(materials.length)} isLoading={isLoadingMaterial} onClick={handleDownloadMaterial} />
                </div>
            </td>
            <td>
                <DropdownTable onclickDelete={handleShowpopup} />

                {
                    isShowDelete
                    &&
                    <div className='fixed inset-0 bg-popup flex justify-center items-center z-20'>
                        <div className='flex flex-col items-center gap-5 rounded-20 bg-white p-5'>
                            <div className='text-center'>
                                <p>이 주문을 삭제하시겠습니까?</p>
                                <p>이 주문이 삭제되면 복원할 수 없습니다.</p>
                            </div>

                            <div className='grid grid-cols-2 gap-2.5'>
                                <ButtonSecon title='취소' onClick={handleShowpopup} className='w-[160px]' />
                                <ButtonPrimary text='확인' className='w-[160px]' onClick={handleDeleteOrder} />
                            </div>
                        </div>
                    </div>
                }

                <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                    {isNotify}
                </div>
            </td>
        </tr>
    )
}
