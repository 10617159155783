import { createAsyncThunk } from "@reduxjs/toolkit";
import { del, get, post } from "../../utils/apis";

export const getOrderFree = createAsyncThunk(
    'order/getfree',
    async () => {
        const response = await get(`order/pdf-fee`)

        return response
    }
)

// get all stamp
export const getOrderStamp = createAsyncThunk(
    'order/getstamp',
    async (stampId) => {
        const response = await get(`order/stamp/${stampId}`)

        return response
    }
)

// get check order
export const getOrderCheckInfor = createAsyncThunk(
    'order/checkinfor',
    async (letterId) => {
        const response = await get(`order/check-orderinfor/${letterId}`)

        return response
    }
)

// get order my page
export const getOrderMypage = createAsyncThunk(
    'order/getmypage',
    async (page = 1) => {
        const response = await get(`userorder/getallorder?page=${page}`)

        return response
    }
)

// get order pdf my page
export const getOrderPdfMypage = createAsyncThunk(
    'order/getPdfMypage',
    async (page = 1) => {
        const response = await get(`userorder/getallorder/pdf?page=${page}`)

        return response
    }
)

// get order completed my page
export const getOrderCompletedMypage = createAsyncThunk(
    'order/getCompletedMypage',
    async (page = 1) => {
        const response = await get(`userorder/pdfpuchase-normal/completed?page=${page}`)

        return response
    }
)

// get order detail 
export const getOrderDetail = createAsyncThunk(
    'order/detail',
    async (orderId) => {
        const response = await get(`userorder/orderdetail/${orderId}`)

        return response
    }
)

// get coupon order
export const getCounponOrder = createAsyncThunk(
    'order/coupon',
    async () => {
        const response = await get(`order/usercoupon`)

        return response
    }
)

// get payment order
export const getPaymentCheckOrder = createAsyncThunk(
    'order/paymentCheck',
    async (letterId) => {
        const response = await get(`order/check-orderpayment/${letterId}`)

        return response
    }
)

// get saved pdf order
export const getSavedPdfMaterialOrder = createAsyncThunk(
    'order/getSavedPdfMaterial',
    async (letterId) => {
        const response = await get(`order/saved-pdfmaterials/${letterId}`)

        return response
    }
)

// saved pdf order
export const savedPdfMaterialOrder = createAsyncThunk(
    'order/savedPdfMaterial',
    async (body) => {
        const { letterId, data } = body

        const response = await post(`order/save/pdf-materials/${letterId}`, data)

        return response
    }
)

// update pdf order
export const updatePdfMaterialOrder = createAsyncThunk(
    'order/updatePdfMaterial',
    async (body) => {
        const { letterId, data } = body

        const response = await post(`order/update/pdf-materials/${letterId}`, data)

        return response
    }
)

// checkout order
export const checkoutOrder = createAsyncThunk(
    'order/checkoutOrder',
    async (data) => {
        const response = await post(`order/addofficeorder`, data)

        return response
    }
)

// checkout order by visa
export const addOfficeOrder = createAsyncThunk(
    'order/addOfficeOrder',
    async (data) => {
        const response = await post(`order/addOfficeOrder/visapay`, data)

        return response
    }
)

// checkout pdf order by card
export const checkoutPdfOrder = createAsyncThunk(
    'order/checkoutPdf',
    async (data) => {
        const response = await post(`order/addpdforder`, data)

        return response
    }
)

// add payment pdf order
export const addEventOrderVisapay = createAsyncThunk(
    'order/addPaymentEvent',
    async (data) => {
        const response = await post(`order/event-product/visapay`, data)

        return response
    }
)

// add payment normal letter order
export const addNormalOrderVisapay = createAsyncThunk(
    'order/addPaymentNormal',
    async (data) => {
        const response = await post(`order/normal-product/visapay`, data)

        return response
    }
)

// add payment point letter order
export const addPointOrderVisapay = createAsyncThunk(
    'order/addPaymentPoint',
    async (data) => {
        const response = await post(`order/pointvoucher-order/visapay`, data)

        return response
    }
)

// check payment pdf order
export const addPdfOrderVisapay = createAsyncThunk(
    'order/addPaymentPdf',
    async (data) => {
        const response = await post(`order/pdforder/visapay`, data)

        return response
    }
)

// check payment pdf order
export const checkPaymentPdf = createAsyncThunk(
    'order/checkPaymentPdf',
    async () => {
        const response = await get(`order/checkpayment/pdforder`)

        return response
    }
)

////// ===================== make by me only event product =====================
export const checkPaymentOrderEvent = createAsyncThunk(
    'order/checkOrderMakebyme',
    async (data) => {
        const response = await post(`order/checkpay-makebyme-order/event`, data)

        return response
    }
)

export const checkoutOrderEvent = createAsyncThunk(
    'order/checkoutEvent',
    async (data) => {
        const response = await post(`order/add-makebyme-order/event`, data)

        return response
    }
)

////// ===================== make by me only handmake product =====================
export const checkPaymentOrderHandmake = createAsyncThunk(
    'order/checkOrderhandmake',
    async (data) => {
        const { purchaseId, letterId } = data
        const response = get(`order/checkpay-makebyme-order/normal/${letterId}?purchasetype=${purchaseId}`)

        return response
    }
)

export const checkoutOrderhandmake = createAsyncThunk(
    'order/checkoutHandmake',
    async (data) => {
        const response = await post(`order/add-makebyme-order/normal`, data)

        return response
    }
)

// delete order admin
export const deleteOrderAdmin = createAsyncThunk(
    'order/delete',
    async (data) => {
        const { page, orderId } = data

        const response = await del(`ordermanagement/order/${orderId}?page=${page}`)

        return response
    }
)