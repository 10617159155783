import React, { useEffect, useState } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderCompletedMypage } from '../../../reduxs/actions/order.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import moment from 'moment/moment'
import { URL_FILE, URL_SERVER } from '../../../utils/apis'
import ButtonPrimary from '../../../components/ButtonPrimary'
import ButtonSecon from '../../../components/ButtonSecon'
import Pagination from '../../../components/Pagination'
import axios from 'axios'

export default function HistoryDownload() {
    const dispatch = useDispatch()

    const [page, setPage] = useState(1)

    const { loadingGetOrderCompletedMypage, dataOrderCompletedMypage, countOrderCompleted } = useSelector(state => state.order)

    const handlePage = (method) => {
        if (method === 'next') {
            const maxPage = Math.ceil(countOrderCompleted / 10)
            setPage(pre => page < maxPage ? pre + 1 : pre)
        }
        else if (method === 'pre') {
            setPage(pre => page <= 1 ? pre : pre - 1)
        }
        else if (typeof method === 'number') {
            setPage(method)
        }
    }

    useEffect(() => {
        dispatch(getOrderCompletedMypage(page))
    }, [page])

    if (loadingGetOrderCompletedMypage || !dataOrderCompletedMypage) {
        return <LoadingUser />
    }

    return (
        <section>
            <TitleSection title='다운로드 기록' small />

            <div className='px-5 rounded-20 border border-strock'>
                <div className='overflow-x-auto'>
                    <table className='w-full text-center'>
                        <thead>
                            <tr className='border-b border-strock'>
                                <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 max-lg:min-w-[60px] w-1/12'>사진</th>
                                <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 max-lg:min-w-[200px]'>영상</th>
                                <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 max-lg:min-w-[120px] w-3/12'>만료</th>
                                <th className='lg:py-5 py-4 px-2 max-lg:text-14_20 max-lg:min-w-[120px] w-2/12'>내려받기</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                dataOrderCompletedMypage.map(item =>
                                    <RowDownloadItem
                                        key={item.order_id}
                                        name={item.product_name}
                                        picture={item.product_picture}
                                        created_at={item.created_at}
                                        status_id={item.order_status_id}
                                        status_name={item.status_name}
                                        allowprint={item.allowPrint}
                                        pdf={item.pdf}
                                    />)
                            }
                        </tbody>
                    </table>
                </div>

                <Pagination totalCount={countOrderCompleted} currentPage={page} handlePage={handlePage} />
            </div>
        </section>
    )
}

const RowDownloadItem = ({ name, picture, created_at, allowprint, pdf }) => {
    const [isDownload, setIsDownload] = useState(false)

    const downloadPdf = async () => {
        try {
            setIsDownload(true); // Hiển thị trạng thái tải xuống

            // Gửi yêu cầu tải xuống file PDF
            const response = await axios.get(`${URL_FILE}/${pdf}`, { responseType: 'blob' });

            // Tạo URL cho file blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.href = url;
            a.download = pdf; // Đặt tên file tải xuống
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url); // Giải phóng bộ nhớ
        } catch (error) {
            console.error("Error while downloading PDF:", error); // Log lỗi
        } finally {
            setIsDownload(false); // Kết thúc trạng thái tải xuống
        }
    }

    return (
        <tr className='border-b border-strock'>
            <td className='py-5'>
                <div className='flex justify-center'>
                    <img src={`${URL_SERVER}${picture}`} alt='product' className='aspect-[7.08/10] object-cover lg:max-w-[100px] max-lg:w-[60px]' />
                </div>
            </td>

            <td className='py-5 px-2.5'>
                <p className='line-clamp-1 max-lg:text-14_20'>{name}</p>
            </td>

            <td className='py-5 px-2.5 max-lg:text-14_20'>
                <div>
                    <p>{moment(created_at).format('YYYY.MM.DD')}</p>

                    {
                        allowprint && <span> (다운로드 기간 : 3일 이내) </span>
                    }
                </div>
            </td>
            <td>
                {
                    allowprint
                        ?
                        <div className='grid gap-2.5 justify-center'>
                            <ButtonPrimary text={isDownload ? 'Download...' : '다운로드'} className='w-fit' onClick={downloadPdf} />
                            {/* <ButtonSecon title='인쇄' className='w-fit min-w-[90px]' /> */}
                        </div>
                        :
                        <button className='hover:text-logoColor duration-300 text-blue max-lg:text-14_20'>
                            만료됨
                        </button>
                }
            </td>
        </tr>
    )
}
