import React from 'react'

export default function TextareaForm({ placeholder, className, name, onChange, value, error, minHeight = 150, classNameAll }) {
    return (
        <div className={`w-full ${classNameAll}`}>
            <textarea
                style={{ minHeight: `${minHeight}px` }}
                name={name}
                onChange={onChange}
                className={`${className} rounded-12 w-full border border-strock lg:py-2 lg:px-4 py-1.5 px-3 max-lg:text-14_20 placeholder:text-deactive resize-none textarea-sizing`}
                placeholder={placeholder}
                value={value}
            />

            {
                error
                &&
                <p className='text-12 text-red mt-1'>{error}</p>
            }
        </div>
    )
}
