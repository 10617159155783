import React, { useContext, useEffect, useRef, useState } from 'react'
import TitleSection from '../../../components/user/TitleSection'
import { DocumentCopy, TruckFast } from 'iconsax-react'
import ButtonSecon from '../../../components/ButtonSecon'
import RadioForm from '../../../components/RadioForm'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import ButtonPrimary from '../../../components/ButtonPrimary'
import SelectCouponPopup from '../../../components/user/SelectCouponPopup'
import { loadTossPayments } from '@tosspayments/tosspayments-sdk'
import { UploadLetterContext } from '../../../contexts/uploadLetterContext'
import { useDispatch, useSelector } from 'react-redux'
import { checkoutOrder, getPaymentCheckOrder } from '../../../reduxs/actions/order.action'
import LoadingUser from '../../../pages/user/LoadingUser'
import PopupFailPayment from '../../../pages/user/PopupFailPayment'
import { addSaveDataUpload } from '../../../reduxs/reducers/order-letter.reducer'
import PopupShowTimeNotify from '../../../components/user/PopupShowTimeNotify'
import PopupShowNotifyBanking from '../../../components/user/PopupShowNotifyBanking'
import { formatMoney } from '../../../utils/renderText'
import CopyButton from '../../../components/user/CopyButton'

export default function PaymentStep({ handleNavigateStep }) {
    const dispatch = useDispatch()

    const paymentRef = useRef()

    const [searchParams] = useSearchParams()
    const savedLetter = searchParams.get('savedLetter')
    const { letter } = useParams()

    const { loadingGetCheckPayment, dataCheckPayment } = useSelector(state => state.order)

    const { formUpload, setFormUpload } = useContext(UploadLetterContext)
    const { ship_fee, coupon_id } = formUpload

    const [isCheck, setIsCheck] = useState()
    const [errorCheck, setErrorCheck] = useState()

    const [widgets, setWidgets] = useState(null)

    const [typePayment, setTypePayment] = useState(3)
    const [isShowPaymentMethod, setIsShowPaymentMethod] = useState(false)
    const [paymentMethodWidget, setPaymentMethodWidget] = useState(null);

    // popup
    const [isShowFailPayment, setIsShowFailPayment] = useState(false)
    const [showNotifycationPayment, setShowNotifycationPayment] = useState(false)

    const [isShowTimeNotify, setIsShowTimeNotify] = useState(false)
    const [isShowBankingNotify, setIsShowBankingNotify] = useState(false)

    // total
    const [totalProduct, setTotalProduct] = useState(0)
    const [coupon, setCoupon] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)

    // choose type payment
    const handleChooseTypePayment = (e) => {
        const type = Number(e.target.value)

        if (type === 1) {
            if (userPoint < 1000) return setIsShowFailPayment('현재 점수가 1000점 이하이므로 사용할 수 없습니다.')

            setCoupon(userPoint)
            setFormUpload({
                ...formUpload,
                pay_method_id: 1
            })
        } else {
            setCoupon(0)

            setFormUpload({
                ...formUpload,
                pay_method_id: type,

            })
        }
        setTypePayment(type)
    }

    const handleBanking = () => {
        if (!isCheck) return setErrorCheck(true)
        setIsShowBankingNotify(true)
    }

    // handle payment by point
    const handlePaymentByPoint = async () => {
        const uploadDatas = {
            letter_id: savedLetter,
            letter_price: letter_price,
            product_id: letter,
            product_total: product_total,
            recipient_address_id: formUpload.recipient_address_id,   // recipient
            recipient_name: formUpload.recipient_name,
            recipient_phone: formUpload.recipient_phone,
            recipient_address: formUpload.recipient_address,
            recipient_zip_code: formUpload.recipient_zip_code,
            sender_address_id: formUpload.sender_address_id,         // sender
            sender_name: formUpload.sender_name,
            sender_phone: formUpload.sender_phone,
            sender_address: formUpload.sender_address,
            sender_zip_code: formUpload.sender_zip_code,
            save_recipient: formUpload.save_recipient ? 1 : 0,
            save_sender: formUpload.save_sender ? 1 : 0,
            stamp_id: formUpload.stamp_id,
            ship_fee: formUpload.ship_fee,
            money_total: money_total,
            money_pay: totalPayment,
            pay_method_id: typePayment,
            coupon_point: formUpload.coupon_point || 0,
            coupon_percent: formUpload.coupon_percent || 0,
            used_point: userPoint,
            bonus_point: (totalPayment * bonusPercent) / 100,
            bankcard_number: ''
        }

        if (coupon < totalProduct || coupon < 10000) {
            setIsShowFailPayment('결제금액보다 사용포인트가 적어 결제에 실패했습니다.')
        } else {
            const result = await dispatch(checkoutOrder(uploadDatas))

            const res = result.payload

            if (res.status) return setIsShowTimeNotify(true)
            else setShowNotifycationPayment(res.message)
        }

        setTimeout(() => {
            setShowNotifycationPayment(false)
        }, 3000);
    }

    // handle payment banking
    const handlePaymentBanking = async () => {
        setIsShowBankingNotify(false)

        const uploadDatas = {
            letter_id: savedLetter,
            letter_price: letter_price,
            product_id: letter,
            product_total: product_total,
            recipient_address_id: formUpload.recipient_address_id,   // recipient
            recipient_name: formUpload.recipient_name,
            recipient_phone: formUpload.recipient_phone,
            recipient_address: formUpload.recipient_address,
            recipient_zip_code: formUpload.recipient_zip_code,
            sender_address_id: formUpload.sender_address_id,         // sender
            sender_name: formUpload.sender_name,
            sender_phone: formUpload.sender_phone,
            sender_address: formUpload.sender_address,
            sender_zip_code: formUpload.sender_zip_code,
            save_recipient: formUpload.save_recipient ? 1 : 0,
            save_sender: formUpload.save_sender ? 1 : 0,
            stamp_id: formUpload.stamp_id,
            ship_fee: formUpload.ship_fee,
            money_total: money_total,
            money_pay: totalPayment,
            pay_method_id: typePayment,
            coupon_point: formUpload.coupon_point || 0,
            coupon_percent: formUpload.coupon_percent || 0,
            used_point: userPoint,
            bonus_point: (totalPayment * bonusPercent) / 100,
        }

        const result = await dispatch(checkoutOrder(uploadDatas))

        const res = result.payload

        if (res.status) return setIsShowTimeNotify(true)
        else setShowNotifycationPayment(res.message)

        setTimeout(() => {
            setShowNotifycationPayment(false)
        }, 3000);
    }

    const handleCloseNotifyPayment = () => {
        setIsShowFailPayment(!isShowFailPayment)
    }

    // handle payment card
    const handlePaymentByCard = async () => {
        if (!isCheck) return setErrorCheck(true)

        const uploadDatas = {
            letter_id: savedLetter,
            letter_price: letter_price,
            product_id: letter,
            product_total: product_total,
            recipient_address_id: formUpload.recipient_address_id,   // recipient
            recipient_name: formUpload.recipient_name,
            recipient_phone: formUpload.recipient_phone,
            recipient_address: formUpload.recipient_address,
            recipient_zip_code: formUpload.recipient_zip_code,
            sender_address_id: formUpload.sender_address_id,         // sender
            sender_name: formUpload.sender_name,
            sender_phone: formUpload.sender_phone,
            sender_address: formUpload.sender_address,
            sender_zip_code: formUpload.sender_zip_code,
            save_recipient: formUpload.save_recipient ? 1 : 0,
            save_sender: formUpload.save_sender ? 1 : 0,
            stamp_id: formUpload.stamp_id,
            ship_fee: formUpload.ship_fee,
            money_total: money_total,
            money_pay: totalPayment,
            pay_method_id: typePayment,
            coupon_point: formUpload.coupon_point || 0,
            coupon_percent: formUpload.coupon_percent || 0,
            used_point: userPoint,
            bonus_point: (totalPayment * bonusPercent) / 100,
            bankcard_number: ''
        }

        dispatch(addSaveDataUpload(uploadDatas))

        try {
            if (paymentMethodWidget && typeof paymentMethodWidget.destroy === "function") {
                await paymentMethodWidget.destroy();
                setPaymentMethodWidget(null);  // Xóa widget khỏi trạng thái
            }

            setIsShowPaymentMethod(true);

            // Đặt số tiền thanh toán
            await widgets.setAmount({
                currency: "KRW",
                value: totalPayment,
            });

            // Render widget phương thức thanh toán
            const paymentMethod = await widgets.renderPaymentMethods({
                selector: "#payment-method",
                variantKey: "DEFAULT",
            });

            setPaymentMethodWidget(paymentMethod);  // Lưu widget vào state
        } catch (error) {
            console.error("Lỗi trong quá trình xử lý thanh toán:", error);
            await paymentMethodWidget.destroy();
            setPaymentMethodWidget(null);
        }
    }

    // confirm payment card
    const confirmPaymentCard = async () => {
        if (!isCheck) return setErrorCheck(true)

        try {
            await widgets.requestPayment({
                orderId: visapay_id,
                orderName: product_name,
                successUrl: window.location.origin + "/success",
                failUrl: window.location.origin + "/fail",
                customerEmail: email,
                customerName: name,
                customerMobilePhone: phone_number,
            });
        } catch (error) {
            console.log(error)
        }
    }

    const handleShowTimeNotify = () => {
        setIsShowTimeNotify(!isShowTimeNotify)
    }

    // get check payment
    useEffect(() => {
        dispatch(getPaymentCheckOrder(savedLetter))
    }, [savedLetter])

    // config widgets
    useEffect(() => {
        const initializeToss = async () => {
            const clientKey = process.env.REACT_APP_CLIENT_KEY_PAYMENT;
            const tossPayments = await loadTossPayments(clientKey);
            const customerKey = process.env.REACT_APP_CUSTOMER_KEY_PAYMENT;

            const widgetsInstance = tossPayments.widgets({
                customerKey,
            });
            setWidgets(widgetsInstance);
        };

        initializeToss();
    }, [])

    // handle show
    useEffect(() => {
        async function handleClickOutside(event) {
            if (paymentRef.current && !paymentRef.current.contains(event.target)) {
                setIsShowPaymentMethod(false)
            }
        }

        if (isShowPaymentMethod) {
            document.addEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [paymentRef, isShowPaymentMethod]);

    // handle calculate total product
    useEffect(() => {
        if (dataCheckPayment && formUpload) {
            setTotalProduct(money_total + ship_fee)
        }
    }, [dataCheckPayment, formUpload])

    // total payment
    useEffect(() => {
        const totalPayments = formUpload?.coupon_percent ? totalProduct - ((totalProduct * formUpload?.coupon_percent) / 100) : totalProduct - (formUpload?.coupon_point || 0)
        setTotalPayment(totalPayments)
    }, [formUpload, coupon, totalProduct, coupon_id])

    if (loadingGetCheckPayment || !dataCheckPayment) {
        return <LoadingUser />
    }

    const { userPoint, bonusPercent, money_total, product_total, letter_price, product_name, name, phone_number, email, visapay_id } = dataCheckPayment

    return (
        <>
            <section className='flex flex-col lg:gap-5 gap-4'>
                <div className='flex flex-col gap-2.5 max-lg:text-14_20'>
                    <TitleSection title='결제방법' small mt={false} />

                    {/* <div className='flex gap-1 text-blue items-center'>
                        <SmsTracking />
                        <span>내일 우체국에 접수됩니다.</span>
                    </div> */}
                </div>

                <div className='flex justify-between items-start w-full max-lg:flex-col gap-4 max-lg:text-14_20'>
                    <div className='flex gap-2 items-center'>
                        <TruckFast variant="Bold" size={20} />
                        <p>등기/우편 비용:</p>
                        <span className='text-logoColor font-bold'>{formatMoney(ship_fee)}원</span>
                    </div>

                    <div className='flex flex-col gap-2.5'>
                        <div className='flex gap-2 items-center justify-between'>
                            <p className='min-w-20'>총액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{formatMoney(totalProduct)}원</span>
                        </div>

                        {
                            coupon
                                ?
                                <div className='flex gap-2 items-center justify-between'>
                                    <p className='min-w-20'>사용 포인트:</p>
                                    <span className='text-logoColor font-bold w-[150px] text-end'>{formatMoney(coupon)}포인트</span>
                                </div>
                                :
                                ''
                        }

                        {
                            (coupon && (totalPayment < coupon))
                                ?
                                <div className='flex gap-2 items-center justify-between'>
                                    <p className='min-w-20'>남은 포인트:</p>
                                    <span className='text-logoColor font-bold w-[150px] text-end'>{formatMoney(coupon - totalPayment)}포인트</span>
                                </div>
                                :
                                ''
                        }

                        {
                            (formUpload.coupon_percent || formUpload.coupon_point)
                            &&
                            <div className='flex gap-2 items-center justify-between'>
                                <p className='min-w-20'>쿠폰:</p>
                                <span className='text-logoColor font-bold w-[150px] text-end'>{formUpload.coupon_percent && `${formUpload.coupon_percent}%`} {formUpload.coupon_point && `${formatMoney(formUpload.coupon_point)}원`}</span>
                            </div>
                        }

                        <div className='flex gap-2 items-center justify-between'>
                            <p className='font-bold min-w-20'>총 구매금액:</p>
                            <span className='text-logoColor font-bold w-[150px] text-end'>{(coupon - totalPayment) > 0 ? 0 : formatMoney(totalPayment)}원</span>
                        </div>
                    </div>
                </div>

                <Table
                    handleChooseTypePayment={handleChooseTypePayment}
                    typePayment={typePayment}
                    bonusPercent={bonusPercent}
                    userPoint={userPoint}
                    isCheck={!!coupon}
                    totalPayment={totalPayment}
                    formUpload={formUpload}
                    setFormUpload={setFormUpload}
                />
            </section>

            <div className='flex justify-center items-center flex-col gap-1'>
                <div className='flex gap-1 max-lg:flex-col items-center'>
                    <RadioForm content='약관에 동의 합니다' isCheck={isCheck} onChange={() => setIsCheck(true)} />
                    <Link to='/policy' target='_blank' className='max-lg:text-14_20 text-blue'>(구매조건 & 환불/취소규정 동의약관)</Link>
                </div>

                {
                    (errorCheck && !isCheck)
                    &&
                    <p className='text-12 text-red'>필수 입력 항목입니다</p>
                }
            </div>

            <div className='flex gap-2.5 justify-center'>
                <ButtonSecon title='이전' className='w-[160px] justify-center' onClick={(handleNavigateStep)} />
                <ButtonPrimary text='구매하기' className='w-[160px] justify-center' onClick={typePayment === 3 ? handlePaymentByCard : (typePayment === 2 ? handleBanking : handlePaymentByPoint)} />
            </div>

            {
                isShowPaymentMethod
                &&
                <div className='fixed inset-0 bg-popup flex justify-center items-center z-50'>
                    <div className='w-[600px] bg-white rounded-20 p-5' ref={paymentRef}>
                        <div className='flex flex-col gap-5 items-center justify-center'>
                            <div id='payment-method' className='w-full'></div>
                            <ButtonSecon title='확인' onClick={confirmPaymentCard} />
                        </div>
                    </div>
                </div>
            }

            {
                ((coupon < totalProduct) && isShowFailPayment)
                &&
                <PopupFailPayment content={isShowFailPayment} handleShow={handleCloseNotifyPayment} />
            }

            {
                isShowTimeNotify
                    ?
                    <PopupShowTimeNotify handleShow={handleShowTimeNotify} />
                    :
                    ''
            }

            {
                isShowBankingNotify
                    ?
                    <PopupShowNotifyBanking handleShow={() => setIsShowBankingNotify(false)} handleConfirm={handlePaymentBanking} />
                    :
                    ''
            }

            <div className={`${showNotifycationPayment ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {showNotifycationPayment}
            </div>
        </>
    )
}

export const Table = ({ handleChooseTypePayment, typePayment, bonusPercent, userPoint, formUpload, setFormUpload }) => {
    return (
        <div className='rounded-20 border-strock border'>
            <div className='flex border-b border-strock'>
                <div className='flex justify-center items-center p-5 border-r border-strock max-lg:w-1/3 max-lg:text-14_20 w-[140px]'>총 보유 포인트</div>

                <div className='p-5 flex flex-col gap-2.5 max-lg:w-2/3'>
                    <RadioForm type='checkbox' name='point' content={`${formatMoney(userPoint)} 포인트 (1,000포인트 이상 사용 가능)`} value={1} onChange={handleChooseTypePayment} isCheck={typePayment === 1} />
                    <p className='text-blue max-lg:text-14_20'>포인트 추가 적립: 후기작성 {bonusPercent}%적립</p>
                </div>
            </div>

            <div className='flex border-b border-strock'>
                <div className='flex justify-center items-center p-5 border-r border-strock max-lg:w-1/3 max-lg:text-14_20 w-[140px]'>쿠폰</div>

                <div className='p-5 max-lg:w-2/3'>
                    <SelectCouponPopup formUpload={formUpload} setFormUpload={setFormUpload} />
                </div>
            </div>

            <div className='flex'>
                <div className='flex justify-center items-center p-5 border-r border-strock max-lg:w-1/3 max-lg:text-14_20 w-[140px]'>결제방법</div>

                <div className='p-5 flex flex-col gap-2.5 max-lg:w-2/3'>
                    <div className='flex lg:gap-5 gap-2.5 max-sm:flex-col'>
                        <RadioForm type='checkbox' isCheck={typePayment === 2} content='계좌이체' onChange={handleChooseTypePayment} value={2} />
                        <RadioForm type='checkbox' isCheck={typePayment === 3} content='신용/체크카드' onChange={handleChooseTypePayment} value={3} />
                    </div>

                    <CopyButton title='Kakao Bank 3333-28-8248474' value='3333288248474' />
                </div>
            </div>
        </div>
    )
}
