import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import TitleSection from '../../components/user/TitleSection'
import ButtonPrimary from '../../components/ButtonPrimary'
import LoadingUser from '../user/LoadingUser'
import { useFormik } from 'formik'
import * as  Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import UpdateImages from '../../sections/admin/createproduct/UpdateImages'
import { getDetailPostOfficeUpdate, updateDetailProductHandmake } from '../../reduxs/actions/admin.action'
import FormUpdateHandmake from '../../sections/admin/createproduct-handmade/FormUpdateHandmake'

export default function EditHanmakeProduct() {
    const dispatch = useDispatch()

    const { id } = useParams()

    const { loadingGetDetailPostOfficeUpdate, dataDetailPostOfficeUpdate } = useSelector(state => state.admin)

    const [isNotify, setIsNotify] = useState(false)

    const formik = useFormik({
        initialValues: {
            frontThumbnail: '',
            endThumbnail: '',
            normals: [],
            descriptions: [],
            pdf: [],
            name: '',
            price: '',
            page_count: '',
            summary_description: '',
            target: '',
            // discount: 0,
            quantity: '',
            category_id: '',
            delete_picture: [],
        },
        validationSchema: Yup.object({
            frontThumbnail: Yup.object().required(ERROR_REQUIRED),
            page_count: Yup.string().required(ERROR_REQUIRED),
            summary_description: Yup.string().required(ERROR_REQUIRED),
            target: Yup.string().required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
            price: Yup.array().of(
                Yup.object().shape({
                    price: Yup.string().required(ERROR_REQUIRED)
                })
            ),
            summary_description: Yup.string().required(ERROR_REQUIRED),
            quantity: Yup.string().required(ERROR_REQUIRED),
            category_id: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            formData.append('frontThumbnail', values.frontThumbnail.filename ? '' : values.frontThumbnail.currentFile)
            formData.append('endThumbnail', values.endThumbnail.filename ? '' : values.endThumbnail.currentFile)

            for (let i = 0; i < values.delete_picture.length; i++) {
                const item = values.delete_picture[i];

                formData.append(`delete_picture[${i}][type]`, item.type)
                formData.append(`delete_picture[${i}][picture_name]`, item.filename)
            }

            for (let i = 0; i < values.descriptions.length; i++) {
                const item = values.descriptions[i];

                if (item.file) {
                    formData.append(`descriptions`, item.currentFile)
                }
            }

            for (let i = 0; i < values.normals.length; i++) {
                const item = values.normals[i];

                if (item.file) {
                    formData.append(`normals`, item.currentFile)
                }
            }

            for (let i = 0; i < values.price.length; i++) {
                const element = values.price[i];

                formData.append(`price[${i}][purchase_type_id]`, element.purchase_type_id)
                formData.append(`price[${i}][price]`, element.price)
                formData.append(`price[${i}][discount]`, element.discount)
                formData.append(`price[${i}][id]`, element.id)
            }

            formData.append('page_count', values.page_count)
            formData.append('summary_description', values.summary_description)
            formData.append('target', values.target)
            formData.append('name', values.name)
            // formData.append('price', values.price)
            // formData.append('discount', values.discount)
            formData.append('quantity', values.quantity)
            formData.append('category_id', values.category_id)

            const result = await dispatch(updateDetailProductHandmake({ productId: id, formData }))

            const res = result.payload

            if (res.status) {
                setIsNotify('성공적으로 저장되었습니다')
            } else {
                setIsNotify(res.message)
            }

            setTimeout(() => {
                setIsNotify()
            }, 3000);
        }
    })

    useEffect(() => {
        dispatch(getDetailPostOfficeUpdate(id))
    }, [id])

    useEffect(() => {
        if (dataDetailPostOfficeUpdate) {
            formik.setValues({
                ...formik.values,
                name: dataDetailPostOfficeUpdate.name,
                quantity: dataDetailPostOfficeUpdate.quantity,
                price: dataDetailPostOfficeUpdate.price,
                // discount: dataDetailPostOfficeUpdate.discount,
                expires: dataDetailPostOfficeUpdate.expires,
                category_id: dataDetailPostOfficeUpdate.category_id,
                page_count: dataDetailPostOfficeUpdate.page_count,
                target: dataDetailPostOfficeUpdate.target,
                summary_description: dataDetailPostOfficeUpdate.summary_description,
                descriptions: dataDetailPostOfficeUpdate.descriptions,
                frontThumbnail: dataDetailPostOfficeUpdate.frontThumbnail,
                endThumbnail: dataDetailPostOfficeUpdate.endThumbnail,
                normals: dataDetailPostOfficeUpdate.normals,
                pdf: dataDetailPostOfficeUpdate.pdf,
            })
        }
    }, [dataDetailPostOfficeUpdate])

    if (loadingGetDetailPostOfficeUpdate || !dataDetailPostOfficeUpdate) {
        return <LoadingUser />
    }

    return (
        <>
            <div className='flex justify-between'>
                <TitleSection title='수정' mt={false} />
                <ButtonPrimary text='저장' onClick={formik.handleSubmit} />
            </div>

            <div className='flex gap-5'>
                <UpdateImages
                    formik={formik}
                    frontImage={formik.values.frontThumbnail}
                    endImage={formik.values.endThumbnail}
                    normalImage={formik.values.normals}
                />

                <FormUpdateHandmake formik={formik} descriptionImage={formik.values.descriptions} />
            </div>

            <div className={`${isNotify ? '' : '-translate-y-[100px]'} fixed top-4 right-1/2 translate-x-1/2 z-50 p-5 shadow-notify bg-lightPink duration-300 rounded-20 w-[400px] text-center text-white`}>
                {isNotify}
            </div>
        </>
    )
}
