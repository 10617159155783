import React, { useState } from 'react'
import TitleSection from '../../components/user/TitleSection'
import ButtonPrimary from '../../components/ButtonPrimary'
import UploadImage from '../../sections/admin/createproduct/UploadImage'
import { useDispatch } from 'react-redux'
import { useFormik } from 'formik'
import * as  Yup from 'yup'
import { ERROR_REQUIRED } from '../../utils/error'
import FormUploadHandmade from '../../sections/admin/createproduct-handmade/FormUploadHandmade'
import { createProductHandmade } from '../../reduxs/actions/product.action'

export default function CreateProductHandmade() {
    const dispatch = useDispatch()

    const [frontImage, setFrontImage] = useState()
    const [endImage, setEndImage] = useState()

    const [normalImage, setNarmalImage] = useState([])
    const [normalImageFirst, setNarmalImageFirst] = useState()

    const formik = useFormik({
        initialValues: {
            frontThumbnail: '',
            endThumbnail: '',
            normals: '',
            normalFirst: '',
            descriptions: '',
            name: '',
            pdf: '',
            page_count: '',
            summary_description: '',
            target: '',
            pricePdf: '',
            priceShip: '',
            discountPdf: 0,
            discountShip: 0,
            quantity: '',
            category_id: '',
        },
        validationSchema: Yup.object({
            // frontThumbnail: Yup.string().required(ERROR_REQUIRED),
            // endThumbnail: Yup.string().required(ERROR_REQUIRED),
            // normals: Yup.string().required(ERROR_REQUIRED),
            // normalFirst: Yup.string().required(ERROR_REQUIRED),
            // descriptions: Yup.string().required(ERROR_REQUIRED),
            pdf: Yup.string().required(ERROR_REQUIRED),
            page_count: Yup.string().required(ERROR_REQUIRED),
            summary_description: Yup.string().required(ERROR_REQUIRED),
            target: Yup.string().required(ERROR_REQUIRED),
            name: Yup.string().required(ERROR_REQUIRED),
            pricePdf: Yup.string().required(ERROR_REQUIRED),
            priceShip: Yup.string().required(ERROR_REQUIRED),
            // discountPdf: 0,
            // discountShip: 0,
            quantity: Yup.string().required(ERROR_REQUIRED),
            category_id: Yup.string().required(ERROR_REQUIRED),
        }),
        onSubmit: async values => {
            const formData = new FormData()

            const arrayPrice = [
                {
                    purchase_type_id: 1,
                    price: values.pricePdf,
                    discount: values.discountPdf,
                },
                {
                    purchase_type_id: 2,
                    price: values.priceShip,
                    discount: values.discountShip,
                },
            ]

            formData.append('frontThumbnail', values.frontThumbnail)
            formData.append('endThumbnail', values.endThumbnail)

            for (let i = 0; i < values.descriptions.length; i++) {
                const element = values.descriptions[i].curentFile;

                formData.append('descriptions', element)
            }

            formData.append('normals', values.normalFirst)

            for (let i = 0; i < values.normals.length; i++) {
                const element = values.normals[i].file;

                formData.append('normals', element)
            }

            for (let i = 0; i < arrayPrice.length; i++) {
                const element = arrayPrice[i];

                formData.append(`price[${i}][purchase_type_id]`, element.purchase_type_id)
                formData.append(`price[${i}][price]`, element.price)
                formData.append(`price[${i}][discount]`, element.discount)
            }

            formData.append('pdf', values.pdf[0])

            formData.append('page_count', values.page_count)
            formData.append('summary_description', values.summary_description)
            formData.append('target', values.target)
            formData.append('name', values.name)
            formData.append('quantity', values.quantity)
            formData.append('category_id', values.category_id)

            const upload = await dispatch(createProductHandmade(formData))

            const res = upload.payload

            if (res.status) {
                formik.resetForm()

                setEndImage()
                setFrontImage()
                setNarmalImage([])
                setNarmalImageFirst()
            }
        }
    })

    return (
        <>
            <div className='flex justify-between'>
                <TitleSection title='상품 추가하기' mt={false} />
                <ButtonPrimary text='상품 추가하기' onClick={formik.handleSubmit} />
            </div>

            <form className='flex gap-5' onSubmit={formik.handleSubmit}>
                <UploadImage
                    formik={formik}
                    frontImage={frontImage}
                    setFrontImage={setFrontImage}
                    endImage={endImage}
                    setEndImage={setEndImage}
                    normalImage={normalImage}
                    setNormalImage={setNarmalImage}
                    normalImageFirst={normalImageFirst}
                    setNormalImageFirst={setNarmalImageFirst}
                />

                <FormUploadHandmade formik={formik} descriptionImage={formik.values.descriptions} />
            </form>
        </>
    )
}
