import React, { useContext, useEffect, useRef, useState } from 'react'
import TitlePage from '../../../components/user/TitlePage'
import { AttachSquare } from 'iconsax-react'
import ButtonPrimary from '../../../components/ButtonPrimary'
import { UploadPdfContext } from '../../../contexts/UploadPdfContext'
// import { PDFDocument } from 'pdf-lib'
import { useDispatch, useSelector } from 'react-redux'
import { getOrderFree } from '../../../reduxs/actions/order.action'
import PdfFileItem from '../../../components/user/PdfFileItem'
import LoadingUser from '../../../pages/user/LoadingUser'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ERROR_5_FILE, ERROR_NOTMATCH_TYPE, ERROR_REQUIRED, ERROR_SIZE_TOO_LARGE } from '../../../utils/error'
import * as pdfjsLib from 'pdfjs-dist/webpack';

export default function UploadPdfStep() {
    const dispatch = useDispatch()
    const pdfInputRef = useRef()

    const [isDragging, setIsDragging] = useState(false);

    const { handleNavigateStep, setFormUpload, formUpload } = useContext(UploadPdfContext)

    const { dataOrderFee, loadingGetFee } = useSelector(state => state.order)

    const [pageCount, setPageCount] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)

    const formik = useFormik({
        initialValues: {
            files: []
        },
        validationSchema: Yup.object().shape({
            files: Yup.array()
                .min(1, ERROR_REQUIRED)
                .max(5, ERROR_5_FILE)
                .test('fileType', ERROR_NOTMATCH_TYPE, (files) => {
                    return files.every((file) => file.pdf_name?.type === 'application/pdf')
                })
                .test('fileSize', ERROR_SIZE_TOO_LARGE, (files) => {
                    return files.every((file) => file.pdf_name?.size < 10 * 1024 * 1024) // 10MB
                })
        }),
        onSubmit: values => {
            setFormUpload({
                ...formUpload,
                pdf: values.files
            })

            handleNavigateStep('next')
        }
    })

    const handleClickInput = () => {
        pdfInputRef.current.click()
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsDragging(false);


        const files = e.dataTransfer.files;
        onChangePdfFile(files);

        e.dataTransfer.clearData();
    };

    const onChangePdfFile = async (files) => {
        const filesArray = Array.from(files)

        const validFiles = filesArray.filter((file) => {
            const isPdf = file.type === 'application/pdf';
            const isUnder10MB = file.size < 10 * 1024 * 1024;
            if (!isPdf) {
                alert(`${file.name} is not a valid PDF file.`);
            }
            if (!isUnder10MB) {
                alert(`${file.name} exceeds the size limit of 10MB.`);
            }
            return isPdf && isUnder10MB;
        });

        const handleFile = validFiles.map((item, index) => {
            return {
                id: Date.now() + index,
                pdf_name: item,
                type: 1,
                price: dataOrderFee?.feePrint[0].price,
                pdf_page: 0
            }
        })

        const limitedFiles = [...formik.values.files, ...handleFile]

        // Lấy số trang từ từng file PDF hợp lệ
        const pageCountsArray = await Promise.all(
            limitedFiles.map(async (file) => {
                const fileReader = new FileReader()
                return new Promise((resolve, reject) => {
                    fileReader.onload = async (e) => {
                        try {
                            const typedArray = new Uint8Array(e.target.result)
                            const pdf = await pdfjsLib.getDocument(typedArray).promise
                            const numPages = pdf.numPages;

                            if (numPages > 60) reject(file.pdf_name)

                            resolve({ id: file.id, numPages: pdf.numPages })
                        } catch (error) {
                            alert(`${file.pdf_name} could not be processed.`)
                            reject('Error reading PDF file')
                        }
                    };
                    fileReader.onerror = () => reject('File reading failed')
                    fileReader.readAsArrayBuffer(file.pdf_name)
                });
            })
        );

        const updatedFiles = limitedFiles.map((file) => {
            const matchedPageCount = pageCountsArray.find((page) => page.id === file.id);
            return {
                ...file,
                pdf_page: matchedPageCount ? matchedPageCount.numPages : 0 // Gán số trang
            };
        });

        formik.setFieldValue('files', updatedFiles);

        // Tính tổng số trang
        const totalPageCount = pageCountsArray.reduce((total, page) => total + page.numPages, 0);

        setPageCount(totalPageCount);
    }

    const handleFileSelect = (e) => {
        onChangePdfFile(e.target.files);
        e.target.value = null; // Reset input để có thể chọn lại cùng một file
    };

    const handleDeleteFile = (id) => {
        const file = formik.values.files.find(item => item.id === id)

        const handlePdfArray = formik.values.files.filter(item => item.id !== id)

        formik.setFieldValue('files', handlePdfArray)
        setPageCount(pre => pre - file.pdf_page)
    }

    const handleChangeType = (e, id, price) => {
        const value = e.target.value

        const updateFilesArray = formik.values.files.map(item => {
            if (item.id === id) {
                return {
                    ...item,
                    type: Number(value),
                    price
                }
            }

            return item
        })

        formik.setFieldValue('files', updateFilesArray)
    }

    useEffect(() => {
        dispatch(getOrderFree())
    }, [])

    useEffect(() => {
        if (formUpload?.pdf) {
            formik.setFieldValue('files', formUpload.pdf)
        }
    }, [formUpload?.pdf])

    useEffect(() => {
        if (formik.values.files) {
            let total = formik.values.files.reduce((total, item) => total + (item.price * item.pdf_page), 0)

            setTotalPrice(total)
        }
    }, [formik.values.files])

    if (loadingGetFee || !dataOrderFee) {
        return <LoadingUser />
    }

    const { feePrint, limitPdf } = dataOrderFee

    return (
        <>
            <TitlePage title='PDF 접수' />

            <div
                onClick={handleClickInput}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                onDragLeave={handleDragLeave}
                className={`cursor-pointer rounded-20 border-2 border-dashed max-w-[536px] max-lg:text-14_20 w-full aspect-video text-center flex flex-col gap-2.5 items-center justify-center ${isDragging ? 'border-blue-500 bg-blue-100' : 'border-deactive'}`}
            >
                <input type='file' name='files' accept='application/pdf' multiple ref={pdfInputRef} hidden onChange={handleFileSelect} />

                <AttachSquare size={60} className='strock1' />

                <div>
                    <p>파일을 여기에 드래그하세요 또는 여기 클릭 </p>
                    <p>{limitPdf.limited}장까지 업로드 가능합니다</p>
                    <p>첨부되는 형식: .PDF</p>
                </div>
            </div>

            <section className='w-full'>
                {
                    formik.values.files
                    &&
                    formik.values.files.map(file =>
                        <PdfFileItem
                            key={file.id}
                            id={file.id}
                            file={file.pdf_name}
                            handleDeleteFile={handleDeleteFile}
                            typeDownload={file.type}
                            handleChangeType={handleChangeType}
                            dataColor={feePrint}
                            pageCount={file.pdf_page}
                        />)
                }
            </section>

            <div className='flex gap-5 justify-end w-full text-logoColor max-lg:text-14_20'>
                <p>총 {pageCount}페이지</p>
                <p>금액: <span className='font-semibold'>{totalPrice}원</span></p>
            </div>

            {
                (formik.touched.files && formik.errors.files)
                    ?
                    <p className='text-center w-full text-12 text-red'>{formik.errors.files}</p>
                    :
                    ''
            }

            <div className='flex gap-2.5 justify-center'>
                <ButtonPrimary text='접수하기' className='w-[160px] justify-center' onClick={formik.handleSubmit} />
            </div>
        </>
    )
}
